import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  Text,
  Flex,
  useColorModeValue as mode,
} from "@chakra-ui/react";

export const CallToAction = ({
  heading,
  body,
  buttonText,
  buttonProps = {},
  ...props
}) => (
  <Container py={{ base: "8", md: "12" }} px={0} textAlign="initial" {...props}>
    <Box
      bg="bg-accent"
      color="on-accent"
      borderRadius="xl"
      px={{ base: "6", lg: "16" }}
      py={{ base: "10", lg: "16" }}
    >
      <Stack
        spacing="8"
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
      >
        <Stack spacing="4" maxW="2xl">
          <Heading size="sm">{heading}</Heading>
          <Text color="on-accent-muted" fontSize="lg">
            {body}
          </Text>
        </Stack>
        <Stack
          spacing="3"
          direction={{ base: "column", sm: "row" }}
          justify={{ base: "start" }}
        >
          <Button
            variant="primary-on-accent"
            size="lg"
            {...buttonProps}
            fontWeight="bold"
          >
            {buttonText}
          </Button>
        </Stack>
      </Stack>
    </Box>
  </Container>
);
